<template>
    <div class="textarea__wrapper">
        <textarea
            :class="['textarea__field']"
            :id="id || `v-textarea-${_uid}`"
            :name="name"
            :required="required"
            :maxlength="maxLength"
            :placeholder="placeholder"
            :value="value"
            @input="onChange"
        ></textarea>

        <div class="textarea__counter">
            {{ resultValue.length }} / {{ maxLength }}
        </div>
    </div>
</template>

<script>
    export default {
        name: 'vTextArea',
        emits: ['update:modelValue'],
        data() {
            return {
                resultValue: this.value || ''
            };
        },
        props: {
            id: {
                type: String,
                default: ''
            },

            name: {
                type: String,
                default: 'v-textarea'
            },

            required: {
                type: Boolean,
                default: false
            },

            placeholder: {
                type: String,
                default: ''
            },

            maxLength: {
                type: Number,
                default: 100
            },

            value: {
                type: String,
                default: ''
            }
        },
        methods: {
            onChange(event) {
                const { target } = event;

                this.resultValue = target.value;
                this.$emit('input', this.resultValue);
            }
        }
    };
</script>

<style lang="scss">
    @import '#sass/_variables.sass';
    @import "#sass/v-style";

    .textarea {
        &__wrapper {
            width: 100%;
            display: flex;
            flex-direction: column;
        }

        &__field {
            display: block;
            width: 100%;
            min-height: 150px;
            padding: 6px 11px;
            outline: 0;
            resize: vertical;
            border-radius: 2px;
            border-width: 1px;
            border-style: solid;
            border-color: #d5dbe4;

            font-size: rem(14);
            font-weight: normal;
            line-height: 1.57;

            transition: border-color 0.3s;

            @include below($mobile) {
                font-size: 14px;
            }

            &:focus {
                border-color: #959595;
            }
        }

        &__counter {
            font-size: 10px;
            text-align: right;
            color: $text-darkgray-3;
            margin: 5px 0 0;
        }
    }
</style>
